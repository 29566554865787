import {
  RISK_SECTION_HIGH,
  RISK_SECTION_LOW,
  RISK_SECTION_MEDIUM,
  RISK_SECTION_VERY_HIGH,
  RISK_SECTION_VERY_LOW,
} from '@consts/risk';
import {
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';

export const riskSettings = [
  formatRisk(RISK_SECTION_VERY_LOW, 'common.very_low', 'risk.very_low', 0, 0),
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', 1, 2),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 3, 5),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 6, 9),
  formatRisk(RISK_SECTION_VERY_HIGH, 'common.very_high', 'risk.very_high', 10, 99999),
];

export const resultSettings = [
  formatResult(
    'En traitant au plus tôt le problème, vous pourrez récupérer plus rapidement et réduire tout risque de développer des problèmes plus importants. Avec Joint Academy, vous pouvez dès maintenant obtenir une évaluation et un traitement adapté.',
    'D’après vos réponses, vos douleurs dans le cou vous causent une légère gêne dans votre vie quotidienne. Toutefois, il est toujours bien de faire évaluer votre douleur dans les temps. À un stade précoce, le développement des douleurs articulaires peut être ralenti et on peut réduire de manière proactive le risque de problèmes sur le long terme. Traiter le problème rapidement vous donnera aussi les meilleures chances de profiter d’une vie active et sans douleur. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs du cou combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre cou, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans le cou et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.',
    0,
    2,
  ),
  formatResult(
    'Mais pas d’inquiétude ! En traitant vos douleurs dans le cou à temps, vous réduirez le risque de développer des problèmes sur le long terme. Avec Joint Academy, vous pouvez dès maintenant obtenir un traitement adapté.',
    'D’après vos réponses, vos douleurs dans le cou vous causent une gêne modérée et semblent avoir un impact négatif dans votre quotidien. Avec des soins adaptés, vous pouvez vous sentir mieux et faire à nouveau les activités que vous aimez. Démarrer votre traitement à un stade précoce permettra de ne pas mettre plus de tension sur vos articulations et de réduire vos douleurs plus rapidement. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs du cou combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre cou, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans le cou et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.',
    3,
    5,
  ),
  formatResult(
    'Mais pas d’inquiétude ! Vous pouvez traiter vos douleurs dans le cou pour profiter à nouveau des activités que vous aimez. Avec Joint Academy, vous pouvez dès maintenant obtenir un traitement adapté.',
    'D’après vos réponses, vous êtes fortement gêné par votre douleur dans le cou et vous avez un risque élevé de développer des problèmes sur le long terme. Avec des soins adaptés, vous pouvez vous sentir mieux et vivre à nouveau avec moins de douleurs et plus de mobilité. Nous vous conseillons donc de vous faire aider pour évaluer ce problème et obtenir un traitement.<br/><br/>Aujourd’hui, le traitement de base pour les douleurs du cou combine des exercices adaptés et une éducation thérapeutique vous permettant de mieux comprendre vos douleurs. C’est le traitement qui est identifié par la recherche comme offrant les meilleurs résultats et étant le plus efficace pour soulager la douleur, et c’est aussi celui qui est recommandé par les directives internationales.<br/><br/>Avec Joint Academy, vous recevez un traitement complet et cliniquement prouvé pour votre cou, depuis votre téléphone. Vous pourrez vous sentir mieux, jour après jour, depuis le confort de votre domicile ! Au début de votre traitement, vous serez affecté à un kinésithérapeute qui vous soutiendra et sera disponible à votre demande tout au long du traitement. Il ou elle vous aidera à mettre en place un programme d’exercice sur-mesure pour vous aider à réduire vos douleurs dans le cou et retrouver votre mobilité. Ces exercices ne prennent que 5 minutes par jour.',
    6,
    99999,
  ),
];

export const questions = [
  formatYesNoQuestion(
    'question1',
    'Votre douleur au cou a-t-elle impacté votre quotidien à la maison et/ou au travail ces deux dernières semaines ?',
  ),
  formatYesNoQuestion(
    'question2',
    'Votre douleur au cou vous a-t-elle empêché de dormir ces deux dernières semaines ?',
  ),
  formatYesNoQuestion(
    'question3',
    'Avez-vous constaté une perte d’intérêt ou de plaisir à faire des choses ces deux dernières semaines ?',
  ),
  formatYesNoQuestion(
    'question4',
    'Vous êtes-vous senti abattu, déprimé ou désespéré ces deux dernières semaines ?',
  ),
  formatYesNoQuestion(
    'question5',
    'Votre douleur au cou vous a-t-elle empêché de vivre une vie normale ces deux dernières semaines ?',
  ),
  formatYesNoQuestion(
    'question6',
    'Votre douleur au cou vous a-t-elle empêché de voir vos amis ou votre famille aussi souvent que d’habitude ces deux dernières semaines ?',
  ),
  formatYesNoQuestion(
    'question7',
    'Avez-vous ce problème au cou depuis au moins 2 ou 3 semaines sans que votre douleur ne diminue ?',
  ),
  formatYesNoQuestion(
    'question8',
    'Pensez-vous qu’il soit dangereux d’être physiquement actif pour une personne dans votre situation ?',
  ),
  formatYesNoQuestion(
    'question9',
    'Pensez-vous que vos douleurs vont durer longtemps ?',
  ),
];

export const references = [
  'Wertli MM, Rasmussen-Barr E, Weiser S, Bachmann LM, Brunner F. The role of fear avoidance beliefs as a prognostic factor for outcome in patients with nonspecific low back pain: a systematic review [published correction appears in Spine J. Aug 1;14(8):a18]. Spine J. 2014;14(5):816-36.e4. doi:10.1016/j.spinee.2013.09.036',
  'Cohen SP, Vase L, Hooten WM. Chronic pain: an update on burden, best practices, and new advances. Lancet. 2021;397(10289):2082-2097. doi:10.1016/S0140-6736(21)00393-7',
  'Luque-Suarez A, Martinez-Calderon J, Falla D. Role of kinesiophobia on pain, disability and quality of life in people suffering from chronic musculoskeletal pain: a systematic review. Br J Sports Med. 2019;53(9):554-559. doi:10.1136/bjsports-2017-098673',
  'Burgess R, Mansell G, Bishop A, Lewis M, Hill J. Predictors of functional outcome in musculoskeletal healthcare: An umbrella review. Eur J Pain. 2020;24(1):51-70. doi:10.1002/ejp.1483',
  'Vlaeyen JWS, Linton SJ. Fear-avoidance and its consequences in chronic musculoskeletal pain: a state of the art. Pain. 2000;85(3):317-332. doi:10.1016/S0304-3959(99)00242-0',
];
